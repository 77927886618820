<template>
  <div class="mt-5">
    <!-- <v-toolbar flat>
        <v-toolbar-title style="font-weight:600">ការកំណត់ VIP Percentage</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar> -->
  </div>
</template>

<script>

// import {mapActions} from "vuex";
export default {
  
  // props: ["params"],
  data() {
    return {
      // name: value,
    };
  },
  methods: {
    // name: value,
  },
};
</script>

